import React from "react";
import {
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Editor from "../../common/Editor";
import { StyledTableRow } from "../../pages/user-data";
import useFetch from "../../helpers/useFetch";
import { error, success } from "../../common/Notifications";
import { useSelector } from "react-redux";
import axios from "axios";
const columns = [
  {
    id: "name",
    label: "Full Name",
    minWidth: 180,
  },
  {
    id: "role",
    label: "Role",
    minWidth: 170,
    align: "center",
  },
  {
    id: "phone_number",
    label: "Phone Number",
    minWidth: 140,
    align: "center",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 150,
    align: "center",
  },
  {
    id: "task",
    label: "Task",
    minWidth: 70,
    align: "center",
  },
  {
    id: "action", // DELETE
    label: "Action",
    minWidth: 90,
    align: "center",
  },
];

const ReviewerSection = ({ information, occurrenceId, isReviwerHead }) => {
  const userRole = useSelector((state) => state.users?.user?.roles?.role);
  const { data: reviewerList, isLoading: reviewerListLoading } = useFetch(
    `api/occurrence-manager/information/reviewer_list/`
  );

  React.useEffect(() => {
    if (!userRole?.toLowerCase()?.includes("super-user")) {
      const hasAction = columns?.findIndex((col) => col?.id === "action");
      if (hasAction !== -1) {
        columns.splice(hasAction, 1);
      }
    }
  }, [userRole]);
  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/reviewers/`
  );
  const [unassignedReviewers, setUnassignedReviewers] = React.useState([]);

  React.useEffect(() => {
    if (!reviewerListLoading && reviewerList?.data && data?.data) {
      setUnassignedReviewers(
        reviewerList?.data?.filter(
          (reviewer) =>
            !data?.data?.find(
              (val) => val?.occurence_reviewer?.id === reviewer.id
            )
        )
      );
    }
  }, [data, reviewerList, reviewerListLoading]);

  // FORM
  const [formData, setFormData] = React.useState({
    reviewer_id: "",
    is_head: false,
  });

  const accessToken = useSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = React.useState(false);

  // ASSIGN
  async function toggleAssignReviewer(id) {
    if (!formData?.reviewer_id && !id) {
      error("Please select a reviewer");
      return;
    }

    setLoading(id ? id : true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${accessToken}`,
        },
      };
      if (id) {
        await axios.delete(
          `${process.env.REACT_APP_BASE_API}api/occurrence-manager/reviewers/${id}/`,
          config
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_BASE_API}api/occurrence-manager/reviewers/`,
          {
            reviewer_id: formData?.reviewer_id,
            is_head: formData?.is_head,
            occurrence_id: occurrenceId,
          },
          config
        );
      }
      setFormData({ reviewer_id: "", is_head: false });
      // REFETCH DATA
      refetch();
      success(`Successfully ${id ? "unassigned" : "assigned"} reviewer`);
    } catch (err) {
      error(err?.response?.data?.message);
    }

    setLoading(false);
  }

  // REVIEWVALUE FORM
  const [reviewValue, setReviewValue] = React.useState("");
  const [isReviewing, setIsReviewing] = React.useState(false);
  React.useEffect(() => {
    if (information) {
      setReviewValue(information?.comment);
    }
  }, [information]);
  async function submitReview() {
    if (!reviewValue) {
      error("Please write a review");
      return;
    }
    setIsReviewing(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/reviewers/${isReviwerHead?.id}/submit_review/`,
        { comment: reviewValue },
        config
      );
      success("Reviewer Details updated successfully");
    } catch (err) {
      error(err?.response?.data?.message);
    }
    setIsReviewing(false);
  }

  return (
    <Stack gap={"32px"}>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Reviewer Section</h2>
      </div>

      <div className="bg-white py-6 px-8 rounded-2xl">
        {userRole?.toLowerCase()?.includes("super-user") && (
          <div className="mb-8">
            <div className="flex items-end gap-10 mt-6">
              <Stack className="flex-1" gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Reviewer
                </Typography>
                <FormControl className="w-full">
                  <Select
                    labelId="simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={formData?.reviewer_id}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        reviewer_id: e.target.value,
                      }));
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="rounded-lg font-semibold"
                  >
                    <MenuItem value="">
                      <p className=" text-secondary ">{"Select Reviewer"}</p>
                    </MenuItem>
                    {unassignedReviewers?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.id}
                        className="text-secondary"
                      >
                        {item.first_name + " " + item.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack className="flex-1" gap={"6px"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.is_head}
                      onChange={(e) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          is_head: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Is Head Reviewer"
                />
              </Stack>
            </div>
            <Button
              variant="contained"
              className="bg-primary text-white capitalize font-bold text-sm px-10 py-2 shadow-none rounded-xl mt-5"
              onClick={() => toggleAssignReviewer()}
            >
              {loading === true ? (
                <CircularProgress size={20} className="text-white" />
              ) : (
                "Assign Reviewer"
              )}
            </Button>
          </div>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className="text-[#AEAEAE] font-medium"
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "medium",
                      fontSize: "14px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 mb-10">
                      <CircularProgress />
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : data?.data?.length > 0 ? (
                data.data?.map((item, index) => (
                  <StyledTableRow key={index} hover>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="text-gray-500 font-medium"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.id === "task" ? (
                          <Typography className="capitalize">
                            {item?.is_head ? "Head Reviewer" : "Reviewer"}
                          </Typography>
                        ) : column.id === "action" ? (
                          <Button
                            variant="contained"
                            className="bg-red-600 text-white capitalize py-2 shadow-none rounded-xl font-bold text-sm"
                            onClick={() => toggleAssignReviewer(item?.id)}
                          >
                            {loading === item?.id ? (
                              <CircularProgress
                                size={20}
                                className="text-white"
                              />
                            ) : (
                              "Unassign"
                            )}
                          </Button>
                        ) : (
                          item[column.id]
                        )}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 mb-10">
                      <p>No reviewer added yet</p>
                    </div>
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="mt-10">
          <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
            Review Details
          </Typography>
          <div
            className={`${
              userRole?.toLowerCase()?.includes("super-user") &&
              "pointer-events-none"
            }`}
          >
            <Editor
              value={reviewValue}
              onChange={(e) => setReviewValue(e)}
              readOnly={!isReviwerHead ? true : false}
            />
            {isReviwerHead && (
              <div className="flex justify-end mt-5">
                <Button
                  className="bg-primary text-white capitalize font-bold text-sm px-10 py-2 shadow-none rounded-xl"
                  onClick={submitReview}
                >
                  {isReviewing ? (
                    <CircularProgress size={20} className="text-white" />
                  ) : (
                    "Submit Review"
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default ReviewerSection;
