import {
  Select,
  Stack,
  Typography,
  FormControl,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import Editor from "../../../common/Editor";
import React from "react";
import useFetch from "../../../helpers/useFetch";
import InputField from "../../common/InputField";
import { error, success } from "../../../common/Notifications";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { severityList } from "../section-owner/SectionForm";
const FactualForm = ({
  goToNext,
  occurrenceId,
  information,
  fetchInformation,
}) => {
  const { data: titleData } = useFetch(`api/occurrence-manager/sectionsetup/`);
  // const { data: sectionMembersData } = useFetch(
  //   `api/occurrence-manager/sections/members_list`
  // );
  const { data: sectionOwnerData } = useFetch(
    `api/occurrence-manager/sections/owners_list`
  );
  const [subSections, setSubSections] = React.useState([]);
  React.useEffect(() => {
    if (information) {
      const sections = information.occurrence_section_group?.find(
        (group) => group?.section_name === "FACTUAL INFORMATION"
      )?.occurence_section;

      setSubSections(sections);
    }
  }, [information]);

  function getSectionOwner(id) {
    if (subSections) {
      const section = subSections.find((section) => section.id === id);
      if (section) {
        const owner = section.occurence_section_member?.find(
          (member) => member?.is_owner
        );
        return owner;
      }
    }
  }

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [formData, setFormData] = React.useState({
    section_name: "",
    section_owner: "",
    severity: "",
    // section_members: [],
    section_group_name: "FACTUAL INFORMATION",
    submission_date: "",
    section_summary: "",
    occurrence: occurrenceId,
  });

  function emptyFormData() {
    setFormData({
      section_name: "",
      section_owner: "",
      // section_members: [],
      section_group_name: "FACTUAL INFORMATION",
      submission_date: "",
      section_summary: "",
      occurrence: occurrenceId,
    });
  }

  const [loading, setLoading] = React.useState(false);
  async function handleAddSection() {
    if (
      !formData.section_name ||
      !formData.section_owner ||
      !formData.submission_date ||
      !formData.section_summary ||
      !formData.severity
      // || formData.section_members.length === 0
    ) {
      error("Please fill all the fields");
      return;
    }

    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/`,
        formData,
        config
      );
      emptyFormData();
      fetchInformation();
      success("Occurrence Details updated successfully");
    } catch (err) {
      error(err.response?.data?.message);
    }
    setLoading(false);
  }
  return (
    <Stack gap={"32px"}>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Occurrence Sections</h2>
      </div>

      <div className="bg-white py-6 px-8 rounded-2xl">
        <div>
          <div className="flex gap-10 mt-6">
            <Stack className="flex-1" gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Section Title
              </Typography>
              <FormControl className="w-full">
                <Select
                  labelId="simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={formData?.section_name}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      section_name: e.target.value,
                    }));
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold"
                >
                  <MenuItem value="">
                    <p className=" text-secondary ">{"Select Section Title"}</p>
                  </MenuItem>
                  {titleData?.data?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name}
                      className="text-secondary"
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack className="flex-1" gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Section Owner
              </Typography>
              <FormControl className="w-full">
                <Select
                  labelId="simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={formData?.section_owner}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      section_owner: e.target.value,
                    }));
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold"
                >
                  <MenuItem value="">
                    <p className=" text-secondary ">{"Select Section Owner"}</p>
                  </MenuItem>
                  {sectionOwnerData?.data?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      className="text-secondary"
                    >
                      {item.last_name + " " + item.first_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </div>

          <div className="flex gap-10 mt-6">
            <Stack className="flex-1">
              <Typography variant="p" color={"#D8D8D8"}>
                Set Submission Date
              </Typography>
              <InputField
                fieldType={"date"}
                placeholder={"Select Date"}
                border={true}
                value={formData?.submission_date}
                onChange={(e) => {
                  const selectedDate = new Date(e);
                  const year = selectedDate.getFullYear();
                  const month = (selectedDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const day = selectedDate
                    .getDate()
                    .toString()
                    .padStart(2, "0");
                  const formattedDate = `${year}-${month}-${day}`;
                  setFormData((prevState) => ({
                    ...prevState,
                    submission_date: formattedDate,
                  }));
                }}
              />
            </Stack>
            <Stack gap={"6px"} className="flex-1">
              <Typography variant="p" color={"#D8D8D8"}>
                Severity
              </Typography>
              <FormControl>
                <Select
                  labelId="Severity"
                  id="Severity"
                  value={formData?.severity}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      severity: e.target.value,
                    }));
                  }}
                  placeholder="Select Severity"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold"
                  sx={{
                    "&.MuiInputBase-root": {
                      minWidth: "220px",
                    },
                  }}
                >
                  <MenuItem value="">
                    <p className="text-sm text-secondary ">Select Severity</p>
                  </MenuItem>
                  {severityList?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.value}
                      className="text-secondary"
                      sx={{
                        outline: "0px",
                        "&.MuiMenuItem-root": {
                          backgroundColor: "#f4f4f4",
                          color: "#7E7E7E",
                          padding: "10px 14px",
                          borderBottom: "1px solid",
                          borderBottomColor: "#CBCBCB",
                          "&:last-child": { border: 0 },
                          "&:active": {
                            backgroundColor: "#051445",
                            color: "#ffffff !important",
                          },
                          "&:hover": {
                            backgroundColor: "#0C33AE",
                            color: "#ffffff !important",
                          },
                          "&:focus-visible": {
                            outline: 0,
                          },
                        },
                      }}
                    >
                      <div className="flex items-center gap-3">
                        <span
                          className="w-3 h-3 rounded-full"
                          style={{
                            backgroundColor: item?.colorCode,
                            border: item?.border,
                          }}
                        ></span>
                        <span>{item?.label}</span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            {/* <Stack className="flex-1" gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Section members
              </Typography>
              <FormControl className="w-full">
                <Select
                  labelId="simple-select-standard-label"
                  multiple
                  id="demo-simple-select-standard"
                  value={formData?.section_members}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      section_members: e.target.value,
                    }));
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold"
                >
                  <MenuItem value="">
                    <p className=" text-secondary ">
                      {"Select Section Members"}
                    </p>
                  </MenuItem>
                  {sectionMembersData?.data?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      className="text-secondary"
                    >
                      {item.last_name + " " + item.first_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack> */}
          </div>
          <Stack className="flex gap-2 mt-6">
            <Typography variant="p" color={"#D8D8D8"}>
              Section Summary
            </Typography>

            <Editor
              value={formData.section_summary}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  section_summary: e,
                }))
              }
            />
            {/* readOnly={userIsOccurenceManager ? false : true} */}
          </Stack>
          <div className="flex justify-end">
            <Button
              variant="outlined"
              className="border-primary px-12 py-2.5 capitalize rounded-xl font-bold text-sm shadow-none mt-6 w-fit"
              onClick={handleAddSection}
            >
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Add Section"
              )}
            </Button>
          </div>

          <Stack className="grid grid-cols-2 gap-10 mt-6">
            {subSections?.map((item, index) => (
              <Link
                to={`/occurrence/manage-occurrence/${occurrenceId}/factual-info/${item?.id}`}
                key={index}
              >
                <div className="rounded-xl border overflow-hidden flex justify-between flex-col text-sm cursor-pointer">
                  <div className="h-44 overflow-hidden px-4 py-4">
                    <h2 className="font-semibold pb-3">{item?.section_name}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          item?.summary ||
                          item?.occurrence_page_section[0]?.content,
                      }}
                    />
                  </div>
                  <div className="flex justify-between gap-4 bg-gray-50 py-2 px-4">
                    <div>
                      <h4 className="text-[#C0C0C0]">Section Owner:</h4>
                      <h2 className="font-semibold">
                        {getSectionOwner(item.id)?.user}
                      </h2>
                    </div>
                    <div>
                      <h4 className="text-[#C0C0C0]">Date Assigned</h4>
                      <h2 className="font-semibold">
                        {new Date(item?.created_at)?.toLocaleDateString()}
                      </h2>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Stack>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          variant="contained"
          className="bg-primary text-white px-12 py-2.5 capitalize rounded-xl font-bold text-sm shadow-none"
          onClick={goToNext}
        >
          Save and Continue
        </Button>
      </div>
    </Stack>
  );
};

export default FactualForm;
