import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Editor from "../../common/Editor";
import {
  Stack,
  Typography,
  Button,
  Dialog,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { ArrowLeft } from "iconsax-react";
import { Input } from "../user-management/ManageProfile";
import DoneDialog from "../../common/DoneDialog";
import ConfirmDialog from "../../common/ConfirmDialog";
import QuerySectionModal from "../../components/occurrence/QuerySectionModal";
import useFetch from "../../helpers/useFetch";
import Loading from "../../common/Loading";
import { useSelector } from "react-redux";
import { error, success } from "../../common/Notifications";
import axios from "axios";
import { InputField } from "../../components";
import { severityList } from "../../components/occurrence/section-owner/SectionForm";

const FactualInformation = () => {
  const { sectionId, occurrenceId } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.users?.user);
  const accessToken = useSelector((state) => state.auth.accessToken);

  // FETCHES
  const { data: titleData } = useFetch(`api/occurrence-manager/sectionsetup/`);
  const { data: sectionMembersData } = useFetch(
    `api/occurrence-manager/sections/members_list`
  );
  const { data: sectionOwnerData } = useFetch(
    `api/occurrence-manager/sections/owners_list`
  );
  const query = new URLSearchParams(window.location.search).get("section_name");
  const { data, isLoading } = useFetch(
    query && query === "Appendix"
      ? `api/occurrence-manager/information/${occurrenceId}/appendices/`
      : `api/occurrence-manager/information/${occurrenceId}/factual_informations/`
  );
  const [section, setSection] = React.useState({});
  const [formData, setFormData] = React.useState({
    section_owner: "",
    submission_date: "",
    section_name: "",
    severity: "",
    section_summary: "",
    section_members: [],
    occurrence: occurrenceId,
  });
  // const [sectionOwner, setSectionOwner] = React.useState("");
  React.useEffect(() => {
    if (data?.data) {
      const section = data?.data?.occurence_section?.find(
        (val) => val.id === sectionId
      );
      setSection(section);
      setValue(section?.occurrence_page_section[0]?.content);
      if (section) {
        const sectionOwner = section?.occurence_section_member?.find(
          (member) => member.is_owner
        );
        const sectionMembers = section?.occurence_section_member?.filter(
          (member) => !member.is_owner
        );
        // setSectionOwner(sectionOwner);
        setFormData((prevState) => ({
          ...prevState,
          submission_date: section.submission_date,
          section_name: section.section_name,
          section_summary: section.summary,
          section_owner: sectionOwner?.member,
          severity: section.severity,
        }));
        if (sectionMembers.length > 0) {
          setFormData((prevState) => ({
            ...prevState,
            section_members: sectionMembers.map((member) => member.member),
          }));
        }
      }
    }
  }, [data]);

  // QUERY
  const [value, setValue] = React.useState("");
  const [isDone, setIsDone] = React.useState(false);
  const [showQueryDialog, setShowQueryDialog] = React.useState(false);

  // DELETE
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  async function handleDelete() {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.delete(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/${sectionId}/`,
        config
      );
      setConfirmDelete(false);
      success("Section deleted successfully");
      navigate(-1);
    } catch (err) {
      error(err?.response?.data?.message);
    }
    setLoading(false);
  }

  // UPDATE
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState(false);
  async function handleUpdate() {
    setLoadingUpdate(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      let data;
      if (query && query === "Appendix") {
        data = {
          ...formData,
          section_group_name: "APPENDICES",
        };
      } else {
        data = {
          ...formData,
          section_group_name: "FACTUAL INFORMATION",
        };
      }
      await axios.put(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/${sectionId}/`,
        data,
        config
      );
      success("Section updated successfully");
      navigate(-1);
    } catch (err) {
      error(err?.response?.data?.message);
    }
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Stack gap={"32px"}>
      <Dialog
        open={showQueryDialog}
        onClose={() => setShowQueryDialog(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            boxShadow: "0px",
            width: "90%",
            maxWidth: "925px",
          },
        }}
      >
        <QuerySectionModal
          setDone={() => {
            setIsDone(true);
            setShowQueryDialog(false);
          }}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => setIsDone(false)}
      >
        <DoneDialog
          title="Success!"
          description="You have successfully queried this section. The section owner will be notified"
        />
      </Dialog>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={true}
          confirmText="Delete"
          title={`Delete ${section?.section_name}`}
          isLoading={loading}
          description="Are you sure you want to delete this section?. This action cannot be undone."
          handleClose={() => setConfirmDelete(false)}
          handleConfirm={handleDelete}
        />
      </Dialog>
      <Dialog
        open={confirmUpdate}
        onClose={() => setConfirmUpdate(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          confirmText="Update"
          title={`Update ${section?.section_name}`}
          isLoading={loading}
          description="Are you sure you want to update this section?. This action cannot be undone."
          handleClose={() => setConfirmUpdate(false)}
          handleConfirm={handleUpdate}
        />
      </Dialog>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>{section?.section_name}</h2>
      </div>

      <div className="bg-white py-6 px-8 rounded-2xl">
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Section Title
            </Typography>
            {query && query == "Appendix" ? (
              <Input
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#D9D9D9",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                disabled={
                  !user?.roles?.role?.toLowerCase()?.includes("occurence")
                }
                className={`${
                  !user?.roles?.role?.toLowerCase()?.includes("occurence")
                    ? "opacity-50 pointer-events-none"
                    : ""
                }`}
                value={formData?.section_name}
                onChange={({ target }) => {
                  setFormData((prev) => ({
                    ...prev,
                    section_name: target.value,
                  }));
                }}
                placeholder="Section title"
              />
            ) : (
              <FormControl className="w-full">
                <Select
                  labelId="lect-standard-label"
                  id="dem-select-standard"
                  value={formData?.section_name}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      section_name: e.target.value,
                    }));
                  }}
                  disabled={
                    !user?.roles?.role?.toLowerCase()?.includes("occurence")
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold"
                >
                  <MenuItem value="">
                    <p className=" text-secondary ">{"Select Section Title"}</p>
                  </MenuItem>
                  {titleData?.data?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name}
                      className="text-secondary"
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
          <Stack
            className={`${
              !user?.roles?.role?.toLowerCase()?.includes("occurence")
                ? "pointer-events-none"
                : "pointer-events-auto"
            } flex-1`}
          >
            <Typography variant="p" color={"#D8D8D8"}>
              Set Submission Date
            </Typography>
            <InputField
              fieldType={"date"}
              placeholder={"Select Date"}
              readOnly
              border={true}
              disabled={
                !user?.roles?.role?.toLowerCase()?.includes("occurence")
              }
              value={formData?.submission_date}
              onChange={(e) => {
                const selectedDate = new Date(e);
                const year = selectedDate.getFullYear();
                const month = (selectedDate.getMonth() + 1)
                  .toString()
                  .padStart(2, "0");
                const day = selectedDate.getDate().toString().padStart(2, "0");
                const formattedDate = `${year}-${month}-${day}`;
                setFormData((prevState) => ({
                  ...prevState,
                  submission_date: formattedDate,
                }));
              }}
            />
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="w-[50%] max-w-[50%]" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Section Owner
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="simple-select-standard-label"
                id="demo-simple-select-standard"
                value={formData?.section_owner}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    section_owner: e.target.value,
                  }));
                }}
                disabled={
                  !user?.roles?.role?.toLowerCase()?.includes("occurence")
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Section Owner"}</p>
                </MenuItem>
                {sectionOwnerData?.data?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    className="text-secondary"
                  >
                    {item.last_name + " " + item.first_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack className="w-[50%] max-w-[50%]" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Section Members
            </Typography>
            <FormControl className="max-w-full">
              <Select
                labelId="simple-select-standard-label"
                multiple
                id="demo-simple-select-standard"
                value={formData?.section_members}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    section_members: e.target.value,
                  }));
                }}
                displayEmpty
                disabled
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold "
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Section Members"}</p>
                </MenuItem>
                {sectionMembersData?.data?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    className="text-secondary"
                  >
                    {item.last_name + " " + item.first_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
        <div className="mt-8 max-w-[50%] pr-5">
          <Stack gap={"6px"} marginBottom={"20px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Severity
            </Typography>
            <FormControl>
              <Select
                labelId="Severity"
                id="Severity"
                value={formData?.severity}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    severity: e.target.value,
                  }));
                }}
                disabled={
                  !user?.roles?.role?.toLowerCase()?.includes("occurence")
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
                sx={{
                  "&.MuiInputBase-root": {
                    // width: "220px",
                  },
                }}
              >
                <MenuItem value="">
                  <p className=" text-secondary ">Select Severity</p>
                </MenuItem>
                {severityList?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item?.value}
                    className="text-secondary"
                    sx={{
                      outline: "0px",
                      "&.MuiMenuItem-root": {
                        backgroundColor: "#f4f4f4",
                        color: "#7E7E7E",
                        padding: "10px 14px",
                        borderBottom: "1px solid",
                        borderBottomColor: "#CBCBCB",
                        "&:last-child": { border: 0 },
                        "&:active": {
                          backgroundColor: "#051445",
                          color: "#ffffff !important",
                        },
                        "&:hover": {
                          backgroundColor: "#0C33AE",
                          color: "#ffffff !important",
                        },
                        "&:focus-visible": {
                          outline: 0,
                        },
                      },
                    }}
                  >
                    <div className="flex items-center gap-3">
                      <span
                        className="w-3 h-3 rounded-full"
                        style={{
                          backgroundColor: item?.colorCode,
                          border: item?.border,
                        }}
                      ></span>
                      <span>{item?.label}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
        <div className="mt-6">
          <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
            Section Summary
          </Typography>
          <div>
          <Editor
           value={formData.section_summary}
           onChange={(e) =>
             user?.roles?.role?.toLowerCase()?.includes("occurence") &&
             setFormData((prev) => ({
               ...prev,
               section_summary: e,
             }))
           }
           readOnly={
             !user?.roles?.role?.toLowerCase()?.includes("occurence")
           }
        />
          </div>
        </div>
        {user?.roles?.role?.toLowerCase()?.includes("occurence") && (
          <div className="flex justify-end mt-6">
            <Button
              className="bg-primary mr-6 py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
              onClick={() => setConfirmUpdate(true)}
            >
              Update
            </Button>
            <Button
              className="bg-red-600 py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
              onClick={() => setConfirmDelete(true)}
            >
              Delete
            </Button>
          </div>
        )}
        <div className="mt-6">
          <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
            Section Details
          </Typography>
          <div>
          <Editor
           value={value}
           readOnly={
            true
           }
        />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-5 mt-7">
          {section?.occurrence_section_media?.map((item, index) => (
            <div
              className="aspect-[4/3] rounded-md overflow-hidden"
              key={index}
            >
              {item?.media_type === "VIDEO" ? (
                <video
                  src={item?.url}
                  controls
                  className="w-full h-full object-cover"
                />
              ) : (
                <img src={item?.url} className="w-full h-full object-cover" />
              )}
            </div>
          ))}
        </div>
        {user?.roles?.role?.toLowerCase()?.includes("occurence") && value && (
          <div className="flex justify-end mt-6">
            <Button
              className="bg-red-600 py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
              onClick={() => setShowQueryDialog(true)}
            >
              Query Section
            </Button>
          </div>
        )}
      </div>

      <div className="flex justify-end">
        <Button
          className="bg-primary py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
    </Stack>
  );
};

export default FactualInformation;
